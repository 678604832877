import React from 'react'
const moment = require('moment')

const BlogHeader = ({ author, postDate }) => {
    if (author) {
        return (
            <div className="c-article__header-meta">
                {author.image !== null && author.image.url ? 
                    <div className="c-article__header-author-img-container">
                        <img src={`${author.image.url}&w=500`} alt={author.name.text} className="c-article__header-author-img" loading="lazy"/>
                    </div> : null}
                <div className="c-article__header-meta-container">
                    <div className="c-article__header-author-info">
                        <div className="c-article__header-author">{author.name.text}</div>
                        {postDate && <time className="c-article__header-date" dateTime={moment(postDate).format("YYYY-MM-DD")}>{moment(postDate).format("Do MMMM YYYY")}</time>}
                    </div>

                    {(author.linkedin_url.url || author.email_address.text) &&
                    <div className="c-article__header-contact">
                        <div className="c-article__header-contact-title">Contact {author.name.text}</div>
                        <ul className="c-article__header-contact-items">
                            {author.linkedin_url.url ? 
                                <li className="c-article__header-contact-item">
                                    <a href={author.linkedin_url.url} className="c-article__header-contact-link" rel="noreferrer" target="_blank">
                                        <svg fill="none" viewBox="0 0 16 16" className="c-article__header-contact-icon"><path fillRule="evenodd" clipRule="evenodd" d="M13.635 13.634h-2.373V9.92c0-.886-.016-2.024-1.234-2.024-1.234 0-1.422.966-1.422 1.961v3.777H6.235V5.998h2.274v1.044h.033c.317-.6 1.09-1.233 2.245-1.233 2.404 0 2.847 1.58 2.847 3.637v4.188ZM3.559 4.955a1.376 1.376 0 1 1-.003-2.753 1.377 1.377 0 0 1 .003 2.754v-.001Zm-1.19 8.679h2.376V5.998H2.37v7.636ZM14.816 0H1.18C.529 0 0 .516 0 1.154v13.691C0 15.482.53 16 1.18 16h13.636c.653 0 1.184-.518 1.184-1.155V1.154C16 .516 15.469 0 14.816 0Z" fill="#1A1A1A"/></svg>
                                        <span className="u-visually-hide">View LinkedIn profile</span>
                                    </a>
                                </li> : null}
                            {author.email_address.text ? 
                                <li className="c-article__header-contact-item">
                                    <a href={`mailto:${author.email_address.text}`} className="c-article__header-contact-link">
                                        <svg fill="none" viewBox="0 0 16 16" className="c-article__header-contact-icon"><path fillRule="evenodd" clipRule="evenodd" d="M0 2h16v12H0V2Zm1.347 4.417v6.213h13.305V6.323L8.006 9.798 1.347 6.417Zm0-1.533 6.65 3.377 6.655-3.48V3.372H1.347v1.512Z" fill="#1A1A1A"/></svg>
                                        <span className="u-visually-hide">Email {author.name.text}</span>
                                    </a>
                                </li> : null}
                        </ul>
                    </div>}
                </div>
            </div>
        )
    } 
    
    return null
}

export default BlogHeader